
import React from 'react'
import { applyThemeBySettings, goToWindow, isNullOrEmpty, isValidatePlate, isValidDocument, obfuscateValue, queryString, toastWarning } from '../../../shared/utils'
import { LoadingContainer, MainContainer, SystemContainer } from '../../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../../shared/layout/ticket-container'
import { getURLFromURI } from '../../../config/api/filesWorker'
import { getBookingById, postBookingAppointmentByPlateAndDocument } from '../../../config/api/form/bookingApi'
import moment from 'moment'
import { ParkCardView } from '../../cards'

class BookingSearchView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: queryString("id"),
            state: "loading",
            document: "",
            items: [],
            plate: ""
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })
        const result = await getBookingById({ id: this.state.id })
        if (result.success) {
            const data = result.data
            this.setState({ ...data })
            const url = await getURLFromURI(result.data.settings.logo)
            this.setState({ url: url })
            await applyThemeBySettings({ settings: { ...result.data.settings } })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    doSearch = async () => {
        const { plate, document } = this.state
        if (!isValidDocument(document)) {
            toastWarning("Informe um documento válido.")
            return
        }
        if (!isValidatePlate(plate)) {
            toastWarning("Informe uma placa válida.")
            return
        }
        this.setState({ state: "loading" })
        const result = await postBookingAppointmentByPlateAndDocument({ plate: plate, document: document })
        if (result.success) {
            const data = result.data
            if (isNullOrEmpty(data)) {
                this.setState({ state: "fill" })
                alert("Nenhum agendamento encontrado.")
                return
            }
            if (data.length === 1) {
                goToWindow(`/reserva/agendamento/?id=${result.data[0].id}`)
            } else {
                this.setState({ items: data })
                this.setState({ state: "select" })
            }
        } else {
            alert(result.message)
            this.setState({ state: "fill" })
        }
    }

    render() {
        return (
            <div style={{ width: "390px", margin: "0 auto" }}>
                <MainContainer>
                    <div className="container-md top-20">
                        {
                            this.state.url &&
                            <ImageContainer url={this.state.url} />
                        }
                        <RegisterContainer>
                            {
                                this.state.state === "not-found" &&
                                <div className="alert alert-danger text-center mt-4">
                                    <span className="badge text-bg-danger">Oops!</span> <p />
                                    <h4>{this.state.errorMessage}</h4>
                                    <span>Entre em contato com o estabelecimento.</span>
                                </div>
                            }
                            {
                                this.state.state === "loading" &&
                                <div className="text-center">
                                    <LoadingContainer />
                                </div>
                            }
                            {
                                this.state.state === "fill" &&
                                <div className="row mt-2">
                                    <div className='text-bold text-size-20 text-center'>BUSCAR AGENDAMENTO</div>
                                    <span className="text-center">Informe o número do seu documento e a placa do veículo no momento do agendamento.</span>
                                    <div className="col-lg-12">
                                        <label>Número do documento</label>
                                        <input type='text' className='form-control form-control-lg mt-2 text-center' value={this.state.document} onChange={(e) => this.setState({ document: e.target.value.toUpperCase() })} />
                                    </div>
                                    <div className="col-lg-12">
                                        <label>Placa do veículo</label>
                                        <input type='text' className='form-control form-control-lg mt-2 text-center' value={this.state.plate} onChange={(e) => this.setState({ plate: e.target.value.toUpperCase() })} />
                                    </div>
                                    <div className="col-lg-12 mt-3">
                                        <div className='btn-group w-100'>
                                            <a href={`/reserva/agendar?id=${this.state.id}`} className="btn btn-secondary btn-lg text-white w-25">
                                                <i className="fa-solid fa-arrow-left" />
                                            </a>
                                            <button onClick={(e) => { this.doSearch() }} className="btn btn-success btn-lg w-75">
                                                <i className="fas fa-search mx-2" />
                                                Buscar
                                            </button>
                                        </div>
                                    </div>
                                    <ParkCardView park={this.state.park} />
                                </div>
                            }
                            {
                                this.state.state === "select" &&
                                <table className='table table-responsive'>
                                    <tbody>
                                        {
                                            this.state.items.map((item, index) =>
                                                <tr>
                                                    <td>
                                                        <div>
                                                            Nome: {obfuscateValue(item.customer.name)}
                                                        </div>
                                                        <div>Veículo:{item.vehicles.join(", ")}</div>
                                                        <div>{moment(item.createdAt._seconds * 1000).format("DD/MM/YYYY")}</div>
                                                    </td>
                                                    <td align='right'>
                                                        <a href={`/reserva/agendamento/?id=${item.id}`} className="btn btn-primary text-white">
                                                            <i className="fa-solid fa-calendar-days mx-2" />
                                                            Visualizar
                                                        </a>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            }
                        </RegisterContainer>
                    </div>
                </MainContainer>
                {
                    this.state.settings &&
                    <SystemContainer settings={this.state.settings} />
                }
            </div>
        )
    }
}

export { BookingSearchView }