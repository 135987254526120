
import React from 'react'
import { applyThemeBySettings, copyClipboard, documentMask, queryString, toCurrency } from '../../../shared/utils'
import { LoadingContainer, MainContainer, SystemContainer } from '../../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../../shared/layout/ticket-container'
import { getURLFromURI } from '../../../config/api/filesWorker'
import { getBookingAppointmentById } from '../../../config/api/form/bookingApi'
import { ParkCardView } from '../../cards'
import Moment from 'moment';
import { QRCodeSVG } from 'qrcode.react';

class BookingAppointmentView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: queryString("id"),
            state: "loading",
            data: null,
            buttonState: "",
            identifier: "",
            searchVehicleButtonTitle: "Buscar",
            passagersQuantity: 1,
            offers: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })
        const result = await getBookingAppointmentById({ id: this.state.id })
        if (result.success) {
            const data = result.data
            this.setState({ ...data })
            const url = await getURLFromURI(result.data.settings.logo)
            this.setState({ url: url })
            await applyThemeBySettings({ settings: { ...result.data.settings } })
            this.setState({ state: "fill" })
            if (data.appointment.status === "pending") {
                this.loadingPixPayment()
            } else if (data.appointment.status === "paid") {
                const ticketURL = `https://ticket.selfparking.com.br?&t=${data.appointment.orderId}&e=${data.appointment?.estacionamentoId}&ty=ticket`
                this.setState({ ticketURL: ticketURL })
            }
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    loadingPixPayment = async () => {
        this.setState({ isLoadingPixPayment: true })
        const result = await getBookingAppointmentById({ id: this.state.id })
        if (result.success) {
            const data = result.data
            if (data.appointment?.status === "pending") {
                setTimeout(() => {
                    this.loadingPixPayment()
                }, 10000)
            } else {
                this.setState({ ...data })
                this.setState({ state: "fill" })
            }
        }
    }

    statusAppointmentColor = (item) => {
        if (item.status === "paid") {
            return "badge text-bg-success text-white"
        }
        if (item.status === "pending") {
            return "badge text-bg-warning text-white"
        }
        if (item.status === "used") {
            return "badge text-bg-primary text-white"
        }
        if (item.status === "canceled" || item.status === "expirated") {
            return "badge text-bg-danger text-white"
        }
        return "badge text-bg-secondary text-white"
    }

    statusAppointmentStatus = (item) => {
        if (item.status === "paid") {
            return "Pago"
        }
        if (item.status === "pending") {
            return "Aguardando pagamento"
        }
        if (item.status === "used") {
            return "Usado"
        }
        if (item.status === "canceled" || item.status === "expirated") {
            return "Cancelado"
        }
        return "Sem status"
    }

    render() {
        return (
            <div className="container-md top-20">
                <MainContainer>
                    {
                        this.state.url &&
                        <ImageContainer url={this.state.url} />
                    }
                    {
                        this.state.state === "not-found" &&
                        <div className="alert alert-danger text-center mt-4">
                            <span className="badge text-bg-danger">Oops!</span> <p />
                            <h4>{this.state.errorMessage}</h4>
                            <span>Entre em contato com o estabelecimento.</span>
                        </div>
                    }
                    {
                        this.state.state === "loading" &&
                        <div className="text-center">
                            <LoadingContainer />
                        </div>
                    }
                    {
                        this.state.state === "fill" &&
                        <RegisterContainer>
                            <div className="row">
                                <div className='col-lg-9'>
                                    <div className='col-lg-7'>
                                        <ParkCardView park={this.state.park} />
                                    </div>
                                    <div className='row'>
                                        <div className="col-lg-6">
                                            <h2 className='mt-2'>Resumo da reserva</h2>
                                            <div>
                                                {<label>Data e hora de entrada: {Moment(this.state.appointment.startDate._seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</label>}
                                            </div>
                                            <div>
                                                {<label>Data e hora de saída: {Moment(this.state.appointment.endDate._seconds * 1000).format("DD/MM/YYYY [às] HH:mm A")}</label>}
                                            </div>
                                            {
                                                this.state.appointment?.vehicles &&
                                                <div>
                                                    <label>Veículo: {this.state.appointment?.vehicles.join(", ")}</label>
                                                </div>
                                            }
                                            {
                                                this.state.appointment?.passagers &&
                                                <div>
                                                    <label>Passageiros: {this.state.appointment?.passagers}</label>
                                                </div>
                                            }
                                            <h4 className='mt-3'>Cliente</h4>
                                            <div>
                                                <label>Nome: {this.state.appointment.customer?.name}</label>
                                            </div>
                                            <div>
                                                <label>Documento: {documentMask(this.state.appointment.customer?.document)}</label>
                                            </div>
                                            <div>
                                                <label>Celular: {this.state.appointment.customer?.cellphone}</label>
                                            </div>
                                            <h4 className='mt-3'>Status do pagamento</h4>
                                            <h3>
                                                <span className={this.statusAppointmentColor(this.state.appointment)}>
                                                    {this.statusAppointmentStatus(this.state.appointment)}
                                                </span>
                                            </h3>
                                            <div className='mt-3 d-none d-lg-block'>
                                                <a href={`/reserva/agendar?id=${this.state.appointment?.booking?.id}`} className="btn btn-secondary text-white">
                                                    <i className="fa-solid fa-arrow-left mx-2" />
                                                    Voltar
                                                </a>
                                            </div>
                                        </div>
                                        <div className="col-lg-5">
                                            <h2 className='mt-2'>Totais da reserva</h2>
                                            {
                                                this.state.appointment?.resume?.products?.map((e, index) =>
                                                    <div key={`produto_${index}`}>
                                                        <label>{e.name}: {toCurrency(e.total)}</label>
                                                    </div>
                                                )
                                            }
                                            {
                                                this.state.appointment?.resume?.parkingValue > 0 &&
                                                <div>
                                                    <label>Valor da estadia: {toCurrency(this.state.appointment?.resume?.parkingValue)}</label>
                                                </div>
                                            }
                                            <h1 className="text-success">{toCurrency(this.state.appointment?.resume?.totalValue)}</h1>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    {
                                        this.state.appointment?.status === "pending" &&
                                        <div className="row mt-3 text-center">
                                            <h2><strong>Pagamento via Chave Pix</strong></h2>
                                            <div className="col-12 mt-2">
                                                {
                                                    this.state.appointment?.pixQRCode?.QRCode && <>
                                                        <img className='img-thumbnail' alt='QRCode Pix' src={this.state.appointment?.pixQRCode?.QRCode} />
                                                        <textarea disabled className="form-control text-center mt-2" rows="5">
                                                            {this.state.appointment?.pixQRCode?.QRCodeText}
                                                        </textarea>
                                                    </>
                                                }
                                            </div>
                                            <div className="mt-3">
                                                <button onClick={(e) => copyClipboard(this.state.appointment?.pixQRCode?.QRCodeText)} className="btn btn-secondary w-100 text-white">
                                                    <i className="fa-solid fa-copy mx-2" />
                                                    Copiar chave
                                                </button>
                                            </div>
                                            <span className="text-center mt-2">Copie a chave Pix acima e faça o pagamento em até 10 minutos no seu banco de preferência.</span>
                                        </div>
                                    }
                                    {
                                        this.state.appointment?.status === "paid" &&
                                        <div className="row mt-3 text-center">
                                            <h2><strong>Cupom de acesso</strong></h2>
                                            <div className="col-12 mt-3 text-center">
                                                <QRCodeSVG value={this.state.ticketURL} size={240} />
                                                <div className='text-center text-size-10 mt-2'>Aponte o QRCode na cancela de entrada do estabelecimento no dia da reserva.</div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.state.appointment?.status === "canceled" &&
                                        <div className="row mt-3 text-center">
                                            <h2><strong>Cupom de acesso cancelado</strong></h2>
                                            <div className="col-12 mt-3 text-center">
                                                <div className='text-center text-danger text-size-10 mt-2'>Este cupom de acesso foi cancelado.</div>
                                            </div>
                                        </div>
                                    }
                                    <div className='mt-3 d-lock d-lg-none'>
                                        <a href={`/reserva/agendar?id=${this.state.appointment?.booking?.id}`} className="btn btn-secondary text-white w-100">
                                            <i className="fa-solid fa-arrow-left mx-2" />
                                            Voltar
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </RegisterContainer>
                    }
                </MainContainer>
                {
                    this.state.settings &&
                    <SystemContainer settings={this.state.settings} />
                }
            </div>
        )
    }
}

export { BookingAppointmentView }