import React from 'react'
import { getCurrentPark } from '../../config/storage/parkRepository'
import { getURLFromURI } from '../../config/api/filesWorker'
// import { Toaster } from 'react-hot-toast';

class MainContainer extends React.Component {

    render() {
        return (
            <>
                {/* <Toaster /> */}
                {this.props.children}
            </>
        )
    }
}

class SystemContainer extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            settings: props.settings
        }
    }

    componentDidMount = async () => {
        const settings = this.state.settings
        if (settings?.custom === true) {
            const resale = settings.resale
            this.setState({ name: resale.name })
            const url = await getURLFromURI(resale.logo)
            this.setState({ url: url })
            this.setState({ site: resale.site })
            this.setState({ sitePrivacidade: resale.sitePrivacidade })
            this.setState({ name: resale.name })
        }
    }

    render() {
        return (
            <div className='text-center mb-4'>
                {
                    this.state.url &&
                    <>
                        <strong className='text-white'>Conheça nossos produtos no site abaixo</strong><p />
                        <a href={this.state.site} target='_blank' rel="noopener noreferrer">
                            <img height={40} style={{ borderRadius: "5px" }} src={this.state.url} alt={"Imagem"} />
                        </a>
                    </>

                }
                {
                    this.state.sitePrivacidade &&
                    <div className='mt-2'>
                        <a href={this.state.sitePrivacidade} target='_blank' rel="noopener noreferrer">
                            <small className='text-white'>Política de privacidade</small>
                        </a>
                    </div>
                }
            </div >
        )
    }
}

class LoadingContainer extends React.Component {

    render() {
        return (
            <>
                <div>
                    <span className="loader" />
                    <h2>Aguarde...</h2>
                </div>
            </>
        )
    }
}

class ParkContainer extends React.Component {

    constructor(props) {
        super(props)
        const park = getCurrentPark()
        this.state = {
            park: park
        }
    }

    render() {
        return (
            <>
                <div>
                    <h2>{this.state.park?.name}</h2>
                    {
                        this.state.park?.address &&
                        <>
                            <small>{this.state.park?.address?.street}, {this.state.park?.address?.number}</small><br />
                        </>
                    }
                    <small>{this.state.park?.times}</small><br />
                    <small>{this.state.park?.document}</small><br />
                    <small>{this.state.park?.phone}</small>
                </div>
            </>
        )
    }
}

export { SystemContainer, LoadingContainer, ParkContainer, MainContainer }