
import React from 'react'
import { applyThemeBySettings, goToWindow, isNullOrEmpty, isValidatePlate, isValidDocument, queryString, toastError, toCurrency } from '../../shared/utils'
import { LoadingContainer, MainContainer, SystemContainer } from '../../shared/layout/system-container'
import { ImageContainer, RegisterContainer } from '../../shared/layout/ticket-container'
import { getURLFromURI } from '../../config/api/filesWorker'
import { getBookingById, postBookingAppointment, postBookingCalculation, postSearchVehicleByPlate } from '../../config/api/form/bookingApi'
import { ParkCardView } from '../cards'
import './index.css'
import { AlertSecondary, AlertWarning } from '../../shared/layout/alert-manager'
import Moment from 'moment';

class BookingView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            id: queryString("id"),
            state: "loading",
            data: null,
            buttonState: "",
            identifier: "",
            searchVehicleButtonTitle: "Buscar",
            passagersQuantity: 1,
            offers: [],
        }
    }

    componentDidMount = async () => {
        this.setState({ state: "loading" })
        const result = await getBookingById({ id: this.state.id })
        if (result.success) {
            const data = result.data
            this.setState({ ...data })
            const url = await getURLFromURI(result.data.settings.logo)
            this.setState({ url: url })
            await applyThemeBySettings({ settings: { ...result.data.settings } })
            this.setState({ state: "fill" })
        } else {
            this.setState({ errorMessage: result.message })
            this.setState({ state: "not-found" })
        }
    }

    handleStartDateChange = (e) => {
        this.setState({ startDate: e.target.value }, this.recalculateDates)
    }

    handleStartTimeChange = (e) => {
        this.setState({ startTime: e.target.value }, this.recalculateDates)
    }

    handleEndDateChange = (e) => {
        this.setState({ endDate: e.target.value }, this.recalculateDates)
    }

    handleEndTimeChange = (e) => {
        this.setState({ endTime: e.target.value }, this.recalculateDates)
    }

    changeTableSelected = (item) => {
        var tables = this.state.booking.tables
        tables.forEach(e => {
            e.isChecked = false
        })
        item.isChecked = !item.isChecked
        if (item.isChecked) {
            this.setState({ tableId: item.id }, this.recalculateDates)
            this.setState({ offers: item.products })
        } else {
            this.setState({ tableId: null }, this.recalculateDates)
            this.setState({ offers: [] })
        }
        this.setState({
            booking: {
                ...this.state.booking,
                tables: tables
            }
        })
    }

    changeOfferSelected = (index, isChecked) => {
        var offers = this.state.offers
        offers[index].isChecked = isChecked ?? false
        this.setState({ offers: offers }, this.recalculateDates)
    }

    recalculateDates = async () => {
        if (isNullOrEmpty(this.state.tableId) ||
            isNullOrEmpty(this.state.startDate) ||
            isNullOrEmpty(this.state.endDate) ||
            isNullOrEmpty(this.state.startTime) ||
            isNullOrEmpty(this.state.endTime)) {
            return
        }
        const startHours = String((parseInt(this.state.startTime.split(":")[0]) + 4)).padStart(2, "0")
        const startMinutes = this.state.startTime.split(":")[1]
        const startDate = `${Moment(this.state.startDate).format("YYYY-MM-DD")}T${startHours}:${startMinutes}:00.000Z`

        const endHours = String((parseInt(this.state.endTime.split(":")[0]) + 4)).padStart(2, "0")
        const endMinutes = this.state.endTime.split(":")[1]
        const endDate = `${Moment(this.state.endDate).format("YYYY-MM-DD")}T${endHours}:${endMinutes}:00.000Z`

        const data = {
            bookingId: this.state.id,
            tableId: this.state.tableId,
            startDate: startDate,
            endDate: endDate,
            offers: this.state.offers.filter(e => e.isChecked === true).map(e => {
                return {
                    id: e.id,
                    quantity: 1 //Será personalizado no futuro
                }
            })
        }
        this.setState({ isCalculatingLoading: true })
        const result = await postBookingCalculation({ data: data })
        this.setState({ isCalculatingLoading: false })
        this.setState({ isEnabled: result.success })
        if (result.success) {
            this.setState({ message: null })
            this.setState({ calculate: result.data })
        } else {
            this.setState({ message: result.message })
        }
    }

    showOfferDetail = (item) => {
        alert(item.observation)
    }

    searchVehicleByEnter = (event) => {
        if (event.key === 'Enter') {
            this.searchVehicle()
        }
    }

    searchVehicle = async () => {
        const plate = this.state.plate
        this.setState({ vehicle: null })
        if (!isValidatePlate(plate)) {
            alert("Informe uma placa válida.")
            return
        }
        this.setState({ searchVehicleButtonTitle: "Buscando..." })
        const result = await postSearchVehicleByPlate({ plate: plate })
        this.setState({ searchVehicleButtonTitle: "Buscar" })
        if (result.success) {
            this.setState({ vehicle: result.data })
        } else {
            this.setState({
                vehicle: {
                    plate: plate
                }
            })
            alert("Nenhum veículo encontrado com esta placa.")
        }
    }

    saveData = async () => {
        if (!this.checkFields()) {
            return
        }
        const startHours = String((parseInt(this.state.startTime.split(":")[0]) + 4)).padStart(2, "0")
        const startMinutes = this.state.startTime.split(":")[1]
        const startDate = `${Moment(this.state.startDate).format("YYYY-MM-DD")}T${startHours}:${startMinutes}:00.000Z`

        const endHours = String((parseInt(this.state.endTime.split(":")[0]) + 4)).padStart(2, "0")
        const endMinutes = this.state.endTime.split(":")[1]
        const endDate = `${Moment(this.state.endDate).format("YYYY-MM-DD")}T${endHours}:${endMinutes}:00.000Z`

        const data = {
            bookingId: this.state.booking.id,
            startDate: startDate,
            endDate: endDate,
            customer: {
                name: this.state.name,
                document: this.state.document,
                cellphone: this.state.cellphone
            },
            tableId: this.state.tableId,
            offers: this.state.offers.filter(e => e.isChecked === true).map(e => {
                return {
                    id: e.id,
                    quantity: 1
                }
            }),
            passagers: parseInt(this.state.passagersQuantity),
            vehicles: [this.state.plate]
        }
        this.setState({ state: "pix-loading" })
        const result = await postBookingAppointment({ data: data })
        const appointmentId = result.data?.id
        if (result.success) {
            goToWindow(`/reserva/agendamento/?id=${appointmentId}`)
        } else {
            if (!isNullOrEmpty(appointmentId)) {
                goToWindow(`/reserva/agendamento/?id=${appointmentId}`)
            } else {
                toastError(result.message)
                this.setState({ state: "fill" })
            }
        }
    }

    checkFields = () => {
        if (isNullOrEmpty(this.state.name)) {
            alert("Preencha o nome do veículo.")
            return false
        }
        if (isNullOrEmpty(this.state.document)) {
            alert("Preencha o documento do cliente.")
            return false
        }
        if (!isValidDocument(this.state.document)) {
            alert("Informe um documento válido.")
            return false
        }
        if (isNullOrEmpty(this.state.cellphone)) {
            alert("Preencha o número do celular.")
            return false
        }
        if (isNullOrEmpty(this.state.plate)) {
            alert("Preencha a placa do veículo.")
            return false
        }
        if (!isValidatePlate(this.state.plate)) {
            alert("Preencha uma placa válida.")
            return false
        }
        return true
    }

    render() {
        return (
            <div className="container-md top-20">
                <MainContainer>
                    {
                        this.state.url &&
                        <ImageContainer url={this.state.url} />
                    }
                    {
                        this.state.state === "not-found" &&
                        <div className="alert alert-danger text-center mt-4">
                            <span className="badge text-bg-danger">Oops!</span> <p />
                            <h4>{this.state.errorMessage}</h4>
                            <span>Entre em contato com o estabelecimento.</span>
                        </div>
                    }
                    {
                        this.state.state === "loading" &&
                        <div className="text-center">
                            <LoadingContainer />
                        </div>
                    }
                    {
                        this.state.state === "fill" &&
                        <RegisterContainer>
                            {
                                this.state.state === "fill" &&
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="d-flex justify-content-between align-items-center">
                                            <h2 className='mt-3'>Reservar estadia</h2>
                                            <a href={`/reserva/buscar-agendamento/?id=${this.state.id}`} className='btn btn-secondary text-white' style={{ fontSize: "13px", backgroundColor: this.state.settings?.backgroundColor }}>
                                                <i className="fa-solid fa-calendar-days mx-2" />
                                                Minhas reservas
                                            </a>
                                        </div>
                                        <ParkCardView park={this.state.park} />
                                        <h5 className="mt-3">Selecione o período da reserva</h5>
                                        <div className="row mt-3">
                                            <div className="col-lg-6">
                                                <label>Data de entrada</label>
                                                <input type="date" onChange={this.handleStartDateChange} value={this.state.startDate} className="form-control form-control-lg" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Horário de entrada</label>
                                                <input type="time" onChange={this.handleStartTimeChange} value={this.state.startTime} className="form-control form-control-lg" />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <label>Data de saída</label>
                                                <input type="date" onChange={this.handleEndDateChange} value={this.state.endDate} className="form-control form-control-lg" />
                                            </div>
                                            <div className="col-lg-6">
                                                <label>Horário de saída</label>
                                                <input type="time" onChange={this.handleEndTimeChange} value={this.state.endTime} className="form-control form-control-lg" />
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            {
                                                !isNullOrEmpty(this.state.booking?.section1) &&
                                                <h5 className="mt-3">{this.state.booking?.section1}</h5>
                                            }
                                            {
                                                this.state.booking.tables?.map(item =>
                                                    <div key={item.id} onClick={(e) => this.changeTableSelected(item)} style={{ backgroundColor: item.isChecked ? this.state.settings?.backgroundColor : "" }} className={item.isChecked ? "container-section container-section-selected" : "container-section container-section-unselected"}>
                                                        <strong>{item.name}</strong>
                                                    </div>
                                                )
                                            }
                                            {
                                                !isNullOrEmpty(this.state.offers) &&
                                                <>
                                                    {
                                                        !isNullOrEmpty(this.state.booking?.section2) &&
                                                        <div className="mt-3">
                                                            <h5>{this.state.booking?.section2}</h5>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.offers?.map((item, index) =>
                                                            <div key={`${item.id}-${this.state.tableId}`} className="container-section container-section-unselected card-header d-flex justify-content-between align-items-center">
                                                                <div className="form-check form-switch">
                                                                    {
                                                                        item.isChecked ?
                                                                            <input className="form-check-input" style={{ backgroundColor: this.state.settings?.backgroundColor }} id={`check_${item.id}_${this.state.tableId}`} value={item.isChecked} onChange={(e) => this.changeOfferSelected(index, e.target.checked)} type="checkbox" />
                                                                            :
                                                                            <input className="form-check-input" id={`check_${item.id}_${this.state.tableId}`} value={item.isChecked} onChange={(e) => this.changeOfferSelected(index, e.target.checked)} type="checkbox" />
                                                                    }
                                                                    <label className="mx-2">{item.name}</label>
                                                                    <strong className="text-success">+{toCurrency(item.price)}</strong>
                                                                </div>
                                                                {
                                                                    !isNullOrEmpty(item.observation) &&
                                                                    <span style={{ fontSize: "13px", color: this.state.settings?.backgroundColor }} onClick={(e) => this.showOfferDetail(item)}>
                                                                        Detalhes
                                                                    </span>
                                                                }
                                                            </div>
                                                        )
                                                    }
                                                </>
                                            }
                                        </div>
                                        <div className='mt-4'>
                                            <h5 className="mt-3">Informe a placa do veículo</h5>
                                            <div className='col-lg-8'>
                                                <div className="input-group">
                                                    <input type="text" className="form-control form-control-lg" onKeyDown={this.searchVehicleByEnter} placeholder="AAA0000 ou AAA0A00" value={this.state.plate} onChange={(e) => this.setState({ plate: e.target.value?.toUpperCase() })} />
                                                    <button className="btn btn-secondary" onClick={this.searchVehicle} style={{ backgroundColor: this.state.settings?.backgroundColor }} disabled={this.state.searchVehicleButtonTitle !== "Buscar"}>{this.state.searchVehicleButtonTitle}</button>
                                                </div>
                                            </div>
                                            {
                                                this.state.vehicle &&
                                                <div className='mt-3'>
                                                    <AlertSecondary tag="Informações do veículo">
                                                        <strong>Placa: </strong> <label>{this.state.plate}</label><br />
                                                        {
                                                            this.state.vehicle?.brand && <> <strong>Marca: </strong> <label>{this.state.vehicle?.brand}</label><br /></>
                                                        }
                                                        {
                                                            this.state.vehicle?.model && <> <strong>Modelo: </strong> <label>{this.state.vehicle?.model}</label><br /></>
                                                        }
                                                        {
                                                            this.state.vehicle?.year && <> <strong>Ano: </strong> <label>{this.state.vehicle?.year}</label><br /></>
                                                        }
                                                    </AlertSecondary>
                                                </div>
                                            }
                                        </div>
                                        {
                                            this.state.booking?.passagersQuantityEnabled &&
                                            <div className='mt-4'>
                                                <h5 className="mt-3">Informe o número de passageiros</h5>
                                                Essa informação é indispensável para reservarmos vagas nas vans e assegurarmos um traslado sem imprevistos.
                                                <div className='col-lg-7 mt-3'>
                                                    <select className="form-select form-select-lg" onChange={(e) => this.setState({ passagersQuantity: e.target.value })}>
                                                        <option value="1">1 passageiro</option>
                                                        <option value="2">2 passageiros</option>
                                                        <option value="3">3 passageiros</option>
                                                        <option value="4">4 passageiros</option>
                                                        <option value="5">5 passageiros</option>
                                                        <option value="6">6 passageiros</option>
                                                        <option value="7">7 passageiros</option>
                                                    </select>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="col-lg-6">
                                        <h2 className='mt-3'>Resumo da reserva</h2>
                                        <div>
                                            {
                                                this.state.startDate && this.state.startTime ?
                                                    <label>Data de entrada: {Moment(this.state.startDate).format("DD/MM/YYYY")} às {this.state.startTime}</label> :
                                                    <label>Data de entrada: - </label>
                                            }
                                        </div>
                                        <div>
                                            {
                                                this.state.endDate && this.state.endTime ?
                                                    <label>Data de saída: {Moment(this.state.endDate).format("DD/MM/YYYY")} às {this.state.endTime}</label> :
                                                    <label>Data de saída: - </label>
                                            }
                                        </div>
                                        {
                                            this.state.vehicle &&
                                            <div>
                                                <label>Veículo: {this.state.vehicle.plate} - {this.state.vehicle.brand} - {this.state.vehicle.year}</label>
                                            </div>
                                        }
                                        {
                                            this.state.passagersQuantity &&
                                            <div>
                                                <label>Passageiros: {this.state.passagersQuantity}</label>
                                            </div>
                                        }
                                        {
                                            this.state.isCalculatingLoading ? <div className="text-center">
                                                <LoadingContainer />
                                            </div>
                                                :
                                                <>
                                                    {
                                                        !isNullOrEmpty(this.state.message) ?
                                                            <h5 className="mt-3 mb-3 text-danger text-center">{this.state.message}</h5> :
                                                            <>
                                                                <h3 className="mt-3">Total</h3>
                                                                {
                                                                    this.state.calculate?.products?.map((e, index) =>
                                                                        <div key={`produto_${e.index}`}>
                                                                            <label>{e.name}: {toCurrency(e.total)}</label>
                                                                        </div>
                                                                    )
                                                                }
                                                                {
                                                                    this.state.calculate?.parkingValue > 0 &&
                                                                    <div>
                                                                        <label>Valor da estadia: {toCurrency(this.state.calculate?.parkingValue)}</label>
                                                                    </div>
                                                                }
                                                                <h1 className="text-success">{toCurrency(this.state.calculate?.totalValue)}</h1>
                                                                <h5 className="mt-3">Dados para a cobrança</h5>
                                                                <div className='col-lg-12 mt-2'>
                                                                    <label>Nome do cliente</label>
                                                                    <input type="text" className="form-control form-control-lg" value={this.state.name} onChange={(e) => this.setState({ name: e.target.value })} />
                                                                </div>
                                                                <div className='col-lg-12 mt-2'>
                                                                    <label>Documento (CPF ou CNPJ)</label>
                                                                    <input type="text" className="form-control form-control-lg" value={this.state.document} onChange={(e) => this.setState({ document: e.target.value })} />
                                                                </div>
                                                                <div className='col-lg-12 mt-2'>
                                                                    <label>Celular</label>
                                                                    <input type="text" className="form-control form-control-lg" value={this.state.cellphone} onChange={(e) => this.setState({ cellphone: e.target.value })} />
                                                                </div>
                                                                <div className="col-lg-12 mt-4">
                                                                    {
                                                                        this.state.isEnabled ?
                                                                            <button onClick={(e) => this.saveData()} style={{ backgroundColor: this.state.settings?.backgroundColor }} className="btn btn-secondary btn-lg w-100 text-white">
                                                                                <i className="fa-brands fa-pix mx-2" />
                                                                                Gerar chave Pix
                                                                            </button> :
                                                                            <button onClick={(e) => this.saveData()} disabled className="btn btn-secondary btn-lg w-100 text-white">
                                                                                <i className="fa-brands fa-pix mx-2" />
                                                                                Gerar chave Pix
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </>
                                                    }
                                                </>
                                        }
                                        {
                                            !isNullOrEmpty(this.state.booking?.description) &&
                                            <div className="mt-3">
                                                <AlertWarning tag="Atenção" message={this.state.booking?.description} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </RegisterContainer>
                    }
                    {
                        this.state.state === "pix-loading" &&
                        <RegisterContainer>
                            <div className='text-center'>
                                <LoadingContainer />
                                <h4>Estamos gerando a chave Pix para pagamento!</h4>
                            </div>
                        </RegisterContainer>
                    }
                    {
                        this.state.settings &&
                        <SystemContainer settings={this.state.settings} />
                    }
                </MainContainer>
            </div>
        )
    }
}

export { BookingView }