import React from 'react';
import { isNullOrEmpty } from '../utils';

class AlertDanger extends React.Component {

    render() {
        return <AlertComponent
            children={this.props.children}
            message={this.props.message}
            type="alert-danger"
            tagClass="text-bg-danger"
            tag={this.props.tag ?? "Atenção"}
        />
    }
}

class AlertWarning extends React.Component {

    render() {
        return <AlertComponent
            children={this.props.children}
            message={this.props.message}
            type="alert-warning"
            tagClass="text-bg-warning"
            tag={this.props.tag ?? "Atenção"}
        />
    }
}

class AlertSecondary extends React.Component {

    render() {
        return <AlertComponent
            children={this.props.children}
            message={this.props.message}
            type="alert-secondary"
            tagClass="text-bg-secondary"
            tag={this.props.tag ?? "Atenção"}
        />
    }
}

class AlertInfo extends React.Component {

    render() {
        return <AlertComponent
            children={this.props.children}
            message={this.props.message}
            type="alert-info"
            tagClass="text-bg-info"
            tag={this.props.tag ?? "Informação"}
        />
    }
}

class AlertSuccess extends React.Component {

    render() {
        return <AlertComponent
            children={this.props.children}
            message={this.props.message}
            type="alert-success"
            tagClass="text-bg-success"
            tag={this.props.tag ?? "Informação"}
        />
    }
}

class AlertComponent extends React.Component {

    render() {
        return (
            <div className='col-lg-12'>
                <div className={`alert ${this.props.type}`}>
                    {
                        !isNullOrEmpty(this.props.tag) && <>
                            <span className={`badge ${this.props.tagClass} text-white`}>{this.props.tag}</span> <br />
                        </>
                    }
                    {this.props.message}
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export { AlertDanger, AlertWarning, AlertSuccess, AlertInfo, AlertSecondary }