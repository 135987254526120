import { fetchApi } from "../fetchFunction";

export const getBookingById = async ({ id }) => {
	const options = {
		method: 'GET'
	}
	return await fetchApi(`/booking/${id}`, options)
}

export const getBookingAppointmentById = async ({ id }) => {
	const options = {
		method: 'GET'
	}
	return await fetchApi(`/booking/appointment/${id}`, options)
}

export const postBookingAppointmentByPlateAndDocument = async ({ plate, document }) => {
	const options = {
		method: 'POST',
		body: JSON.stringify({
			plate: plate.toUpperCase(),
			document: document
		})
	}
	return await fetchApi(`/booking/appointments`, options)
}

export const postBookingCalculation = async ({ data }) => {
	const options = {
		method: 'POST',
		body: JSON.stringify(data)
	}
	return await fetchApi(`/booking/calculate`, options)
}

export const postBookingAppointment = async ({ data }) => {
	const options = {
		method: 'POST',
		body: JSON.stringify(data)
	}
	return await fetchApi(`/booking/appointment`, options)
}

export const postSearchVehicleByPlate = async ({ plate }) => {
	const options = {
		method: 'GET',
	}
	return await fetchApi(`/vehicle/${plate}`, options)
}